import React from 'react';
import EthereumLogo from '../../Icons/Ethereum';
import { AllNftsResponseData, FavoriteNftResponse } from '../../interfaces/components';
import { Box, Image, Text, Divider, Button, useToast } from '@chakra-ui/react';
import { addNftToFavorite } from '../../api/nfts/apis';
import NftOwner from '../common/NftOwner';
import HeartIconFilled from '../../Icons/HeatIconFilled';

interface NftProps {
  favNfts?: FavoriteNftResponse[];
  myNfts?: AllNftsResponseData[];
  removeFromFav?: boolean;
  tabIndex: number;
  ownerName?: string;
  onNftClick: (nft: AllNftsResponseData | FavoriteNftResponse) => void;
  setRemoveFromFav?: (remove: boolean) => void;
}

export default function Nft(props: NftProps) {
  const { myNfts, ownerName, tabIndex, favNfts, removeFromFav, onNftClick, setRemoveFromFav } = props;
  const toast = useToast();

  const unFavoriteNft = async (id: string) => {
    try {
      const res = await addNftToFavorite(id);
      if (res?.message === 'NFT unliked') {
        setRemoveFromFav && setRemoveFromFav(!removeFromFav);
      }
    } catch (error: unknown) {
      toast({
        title: 'Something went wrong while removing NFT from favorites',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {tabIndex === 0 ? (
        <>
          {myNfts &&
            myNfts?.length > 0 &&
            myNfts?.map(
              (nft, i) =>
                nft?.display_image_url && (
                  <Box
                    key={i}
                    borderRadius="26px"
                    border="1px solid rgba(255, 255, 255, 0.10)"
                    backgroundColor="rgba(255, 255, 255, 0.10)"
                    width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                    paddingTop="30%"
                    position="relative"
                    padding="16px 16px 24px 16px"
                    cursor="pointer"
                    onClick={() => onNftClick(nft)}
                  >
                    <Image
                      src={nft?.display_image_url}
                      width={['inherit', 'inherit', '100%', '100%', '100%']}
                      height="314px"
                      maxHeight="314px"
                      objectFit="cover"
                      cursor="pointer"
                      borderRadius="12px"
                    />
                    <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                      {nft?.name}
                    </Text>
                    <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                    <NftOwner nftOwner={nft?.owner} ownerName={ownerName} />
                    <Box display="flex" flexDirection="column" gap="5px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                        <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                          Network
                        </Text>
                        <Box
                          borderRadius="19px"
                          background="rgba(255, 255, 255, 0.10)"
                          padding="4px 4px 4px 10px"
                          display="flex"
                          gap="6px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            Ethereum
                          </Text>
                          <EthereumLogo boxSize={4} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ),
            )}
        </>
      ) : (
        <>
          {favNfts &&
            favNfts?.length > 0 &&
            favNfts?.map(
              (nft, i) =>
                nft?.display_image_url && (
                  <Box
                    key={i}
                    borderRadius="26px"
                    border="1px solid rgba(255, 255, 255, 0.10)"
                    backgroundColor="rgba(255, 255, 255, 0.10)"
                    width={['-webkit-fill-available', '-webkit-fill-available', '49%', '49%', '32%']}
                    paddingTop="30%"
                    position="relative"
                    padding="16px 16px 24px 16px"
                    cursor="pointer"
                    // onClick={() => onNftClick(nft)}
                  >
                    <Image
                      src={nft?.display_image_url}
                      width={['inherit', 'inherit', '100%', '100%', '100%']}
                      height="314px"
                      maxHeight="314px"
                      objectFit="cover"
                      cursor="pointer"
                      borderRadius="12px"
                    />
                    <Text color="#fff" fontSize="16px" fontWeight="700" marginTop="18px" marginBottom="18px">
                      {nft?.name}
                    </Text>
                    <Divider border="1px solid rgba(255, 255, 255, 0.10) !important" marginBottom="10px" />
                    <NftOwner nftOwner={nft?.owner} />
                    <Box display="flex" flexDirection="column" gap="5px">
                      <Box display="flex" flexDirection="row" justifyContent="space-between" marginTop="10px">
                        <Text color="rgba(255, 255, 255, 0.50);" fontSize="14px">
                          Network
                        </Text>
                        <Box
                          borderRadius="19px"
                          background="rgba(255, 255, 255, 0.10)"
                          padding="4px 4px 4px 10px"
                          display="flex"
                          gap="6px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text color="#FFFFFF" fontSize="14px" fontWeight="600">
                            Ethereum
                          </Text>
                          <EthereumLogo boxSize={4} />
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" marginTop="18px">
                      <Button
                        width="100%"
                        borderRadius="15px"
                        backgroundColor="#222"
                        border=" 0.5px solid rgba(255, 255, 255, 0.20)"
                        padding="16px 32px"
                        color="#FFF"
                        fontSize="16px"
                        fontWeight="600"
                        h="44px"
                        _hover={{
                          color: 'white',
                          bg: 'rgba(255, 255, 255, 0.20)',
                        }}
                        leftIcon={<HeartIconFilled />}
                        onClick={() => unFavoriteNft(nft?.identifier)}
                      >
                        Favorited
                      </Button>
                    </Box>
                  </Box>
                ),
            )}
        </>
      )}
    </>
  );
}
