import { Chain } from 'viem';

const chainId = 232232;

export const layerxChain: Chain = {
  id: chainId,
  name: 'Layerx Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'Layerx',
    symbol: 'Layerx',
  },
  rpcUrls: {
    public: {
      http: ['https://rpc-testnet.layerxscan.com'],
    },
    default: {
      http: ['https://rpc-testnet.layerxscan.com'],
    },
  },
  blockExplorers: {
    default: { name: 'LayerxScan', url: ' https://testnet.layerxscan.com' },
  },
  testnet: true,
};
